/* 
adaptive from repository github
site: https://github.com/dai-shi/react-hooks-global-state/blob/main/src/createGlobalState.ts
*/

import { SetStateAction, useCallback } from 'react';
import { create } from 'zustand';

const validateStateKey = (keys: string[], stateKey: string) => {
  if (!keys.includes(stateKey)) {
    throw new Error(
      `'${stateKey}' not found. It must be provided in initialState as a property key.`
    );
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
const isFunction = (fn: unknown): fn is Function => typeof fn === 'function';

const updateValue = <Value>(
  oldValue: Value,
  newValue: SetStateAction<Value>
) => {
  return isFunction(newValue) ? newValue(oldValue) : newValue;
};

export const createState = <State extends object>(initialState: State) => {
  const useStore = create<State>(() => initialState);
  type StateKeys = keyof State;
  const keys = Object.keys(initialState);

  const setState = <StateKey extends StateKeys>(
    stateKey: StateKey,
    update: SetStateAction<State[StateKey]>
  ) => {
    validateStateKey(keys, stateKey as string);
    useStore.setState(
      (previousState: any) =>
        ({
          [stateKey]: updateValue(previousState[stateKey], update),
        }) as Pick<State, StateKey> as Partial<State>
    );
  };

  const useState = <StateKey extends StateKeys>(stateKey: StateKey) => {
    validateStateKey(keys, stateKey as string);
    const selector = useCallback((state: State) => state[stateKey], [stateKey]);
    const partialState = useStore(selector);
    const updater = useCallback(
      (u: SetStateAction<State[StateKey]>) => setState(stateKey, u),
      [stateKey]
    );
    return [partialState, updater] as const;
  };

  const getState = <StateKey extends StateKeys>(stateKey: StateKey) => {
    if (process.env.NODE_ENV !== 'production') {
      validateStateKey(keys, stateKey as string);
    }
    return useStore.getState()[stateKey];
  };

  const subscribe = <StateKey extends StateKeys>(
    stateKey: StateKey,
    listener: (value: State[StateKey]) => void
  ) => {
    useStore.subscribe((state: any, prevState: any) => {
      if (state[stateKey] !== prevState[stateKey]) {
        listener(state[stateKey]);
      }
    });
  };

  return {
    useState,
    getState,
    setState,
    subscribe,
  };
};
