import { Route, Routes } from 'react-router-dom';
import { Login } from './component';
import { AuthProvider } from '../../providers';

const routes = () => {
  return (
    <Routes>
      <Route element={<AuthProvider />}>
        <Route path='login' element={<Login />} />
      </Route>
    </Routes>
  );
};

export default routes;
