import {
  HiOutlineViewGridAdd,
} from 'react-icons/hi';

const navigation = [
  {
    title: 'Organization',
    to: '/organizations',
    icon: HiOutlineViewGridAdd,
    access: [],
  },
];

export { navigation };
