import { UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BaseApis } from '../types/apis';
import { BaseProviderProps } from '../types/components';

import { storageKey } from '../constans';

import { setAuth, useAuth } from '../stores/auth';
// import notification from '@stores/notification'

import { AuthServices, AuthTypes } from '../features/auth';

import { validAuth } from '../utils/base';
import { storage } from '../utils/storage';

import {
  //   ButtonRefresh,
  //   DetailError,
  LoadingScreen,
  //   Mistake,
} from '../components/elements/loading-screen';

const GlobalAuthProvider: React.FC<BaseProviderProps> = ({ children }) => {
  const getUserMe = AuthServices.useUserMe({
    config: {
      enabled: false,
    },
  }) as UseQueryResult<AuthTypes.UserMeDTO, AxiosError<BaseApis<null>>>;

  const location = useLocation();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const hasValidAuth = validAuth().isValid;
  useEffect(() => {
    if (hasValidAuth && !auth) {
      getUserMe.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (!getUserMe.data) return;
    const userMe = getUserMe?.data;
    if (!userMe) {
      //   notification.error('Terdapat kesalahan saat mengambil user info')
      console.log('Terdapat kesalahan saat mengambil user info');
      storage.removeItem(storageKey.authToken);
      navigate('/login');
    }
    setAuth(userMe);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserMe.data]);

  if ((getUserMe.isSuccess && hasValidAuth) || !hasValidAuth) {
    return <>{children}</>;
  }

  if (getUserMe.isLoading && hasValidAuth) {
    return <LoadingScreen reason='Get user info' />;
  }

  if (
    getUserMe.error &&
    getUserMe.error.code === axios.AxiosError.ERR_BAD_REQUEST
  ) {
    storage.removeItem(storageKey.authToken);
    return <>{children}</>;
  }

  if (getUserMe.isError) {
    return (
      //   <DetailError
      //     error={getUserMe.error}
      //     isReload
      //     customAction={<ButtonRefresh />}
      //   />
      <h1>error get user</h1>
    );
  }

  return (
    // <Mistake
    //   code='Opps'
    //   title='Something went wrong :('
    //   subtitle={`Unhandled status: ${getUserMe.status}`}
    // >
    //   <div className='mt-6'>
    //     <ButtonRefresh />
    //   </div>
    // </Mistake>
    <h1>Something went wrong :(</h1>
  );
};

export default GlobalAuthProvider;

// const GlobalAuthProvider = () => {
//     return ''
// }

// export default GlobalAuthProvider
