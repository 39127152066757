import { cloneElement, forwardRef, useMemo } from 'react';

import { mergeRefs } from '../../../utils/refs';

import { useDropdownContext } from './Dropdown';
import { DropdownHandlerProps } from './Dropdown.types';

export const DropdownHandler = forwardRef<HTMLDivElement, DropdownHandlerProps>(
  ({ children, ...rest }, ref) => {
    const { getReferenceProps, reference } = useDropdownContext();

    const mergedRef = useMemo(
      () => mergeRefs([ref, reference]),
      [ref, reference]
    );

    return cloneElement(children, {
      ...getReferenceProps({
        ...rest,
        ref: mergedRef,
      }),
    });
  }
);

DropdownHandler.displayName = 'DropdownHandler';
