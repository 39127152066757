import { roleMatriks, roleAction } from '../constans';

import { createState } from '../libs/state';

import { AuthTypes } from '../features/auth';

const { setState, useState } = createState({
  auth: null as AuthTypes.UserAuthState,
});

export const useAuth = () => useState('auth');
export const setAuth = (val: AuthTypes.UserMeState) => {
  if (!val) return;
  const role = roleMatriks[val.data.org_id];
  const navigation = Object.keys({}).reduce((acc, val) => {
    if (role[val].includes(roleAction.read)) {
      acc.push(val);
    }
    return acc;
  }, [] as string[]);

  setState('auth', {
    user: val,
    navigation,
    role,
  });
};
