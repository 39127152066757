import { componentConfig } from '../constans';

import { createState } from '../libs/state';

import {
  ConfirmationAction,
  ConfirmationAlertProps,
  ConfirmationConfirmProps,
  ConfirmationData,
  ConfirmationFunc,
  ConfirmationIconType,
  ConfirmationState,
  ConfirmationType,
} from '../components/confirmation';

const { setState, useState } = createState({
  confirmation: null as ConfirmationState,
});

export const useConfirmation = () => useState('confirmation');

export const addConfirmation = (props: ConfirmationData) => {
  const defaultClose: ConfirmationAction = (close) => {
    close();
  };
  setState('confirmation', {
    ...props,
    onConfirm: props?.onConfirm || defaultClose,
    onReject: props?.onReject || defaultClose,
  } as ConfirmationState);
};

export const removeConfirmation = () => {
  setState('confirmation', null);
};

const confirmation: ConfirmationFunc = {
  confirm: (val: ConfirmationConfirmProps) => {
    addConfirmation({
      ...val,
      type: ConfirmationType.confirm,
      title: val?.title || componentConfig.confirmation.confirm.title,
      message: val?.message || componentConfig.confirmation.confirm.message,
      icon: val?.icon || ConfirmationIconType.warning,
    });
  },
  alert: (val: ConfirmationAlertProps) => {
    addConfirmation({
      ...val,
      type: ConfirmationType.alert,
      title: val?.title || componentConfig.confirmation.alert.title,
      message: val?.message || componentConfig.confirmation.alert.message,
      icon: val?.icon || ConfirmationIconType.success,
    });
  },
};

export default confirmation;
