import { HiOutlinePlus } from 'react-icons/hi';
import { Breadcrumbs } from '../breadcrumbs';
import { ButtonLink } from '../button';
import { BaseLayoutProps } from './BaseLayout.types';

export const BaseLayout: React.FC<BaseLayoutProps> = (props) => {
  const {
    title,
    breadcrumbTitle,
    children,
    // access
  } = props;

  // const hasCreate = access?.hasCreate() || false;

  return (
    <div className='wrapper-dashboard'>
      <Breadcrumbs
        items={[
          {
            to: '/dashboard/organizations',
            content: 'home',
          },
          {
            to: '',
            content: breadcrumbTitle || title,
          },
        ]}
      />
      <div className='w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8'>
        <div className='flex w-full justify-between mb-[2rem]'>
          <h1 className='title'>{title}</h1>
          {/* {hasCreate ? (
            <ButtonLink
              to="create"
              variant="solid"
              color="primary"
              leftIcon={<HiOutlinePlus className="mr-1 h-5 w-5" />}
            >
              Add
            </ButtonLink>
          ) : null} */}
          <ButtonLink
            to='create'
            variant='solid'
            color='primary'
            leftIcon={<HiOutlinePlus className='mr-1 h-5 w-5' />}
          >
            Add
          </ButtonLink>
        </div>
        {children}
      </div>
    </div>
  );
};
