import { AxiosError, AxiosResponse } from 'axios';

import { BaseApis } from '../types/apis';

export const getErrorMessage = <T extends BaseApis<any>>(
  res: AxiosError<T>
) => {
  return res?.response?.data.message || res.message;
};

export const getMessage = <T extends BaseApis<any>>(res: AxiosResponse<T>) => {
  return res?.data.message || res.statusText;
};
