import ReactLoading from 'react-loading';
import { LoadingScreenProps } from './LoadingScreen.types';

export function LoadingScreen(props: LoadingScreenProps): JSX.Element {
  return (
    <div className='loading-screen mx-auto flex min-h-screen w-full items-center justify-center'>
      <div className='flex flex-col items-center'>
        <ReactLoading type='spin' color='#0000FF' height={100} width={50} />
        {props.reason && (
          <span className='mt-4 max-w-[220px] text-center text-base text-gray-800'>
            {props.reason}
          </span>
        )}
      </div>
    </div>
  );
}
