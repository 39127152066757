import { z } from 'zod';

const loginForm = z.object({
  username: z.string({
    required_error: 'Username harus di isi',
    invalid_type_error: 'Username harus berisi karakter',
  }),
  password: z.string({
    required_error: 'Password harus di isi',
    invalid_type_error: 'Password harus berisi karakter',
  }),
});

export { loginForm };
