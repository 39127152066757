/**
 * @param  {any} value
 * @param  {string} key
 * @returns void
 */
export const setLocalStorage = (key: string, value: any): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * @returns any
 */
export const getLocalStorage = (key: string): any => {
  const localStorageItem = localStorage.getItem(key);
  return localStorageItem ? JSON.parse(localStorageItem) : null;
};

/**
 * @returns void
 */
export const deleteLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const localStorageUtils = {
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
};
