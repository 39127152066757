import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import useButtonClass from './Button.styles';
import { ButtonLinkProps } from './ButtonLink.types';

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (props, ref) => {
    const {
      leftIcon,
      rightIcon,
      children,
      className,
      color,
      size,
      variant,
      isFluid,
      ...rest
    } = props;
    const classes = useButtonClass({ size, variant, isFluid });

    return (
      <Link
        ref={ref}
        className={clsx(classes, className)}
        data-color={color || undefined}
        {...rest}
      >
        <>
          {leftIcon && leftIcon}
          {children}
          {rightIcon && rightIcon}
        </>
      </Link>
    );
  }
);

ButtonLink.displayName = 'ButtonLink';
