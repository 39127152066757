import { ConfirmationDismissType } from '../components/confirmation';
import { ModalDismissType } from '../components/elements';
import { NotificationPosition } from '../components/notifications';
import { LocalKey } from '../utils/storage';

const API_BASE_URL = (window as Window).__RUNTIME_CONFIG__
  .REACT_APP_API_BASE_URL;

const storageKey = {
  authToken: new LocalKey<string | null | undefined>('auth-token', ''),
};

const DateFormat = {
  base: 'Y-MM-dd',
  detail: 'dd MMM Y HH:mm:ss',
  table: 'MM-dd-Y',
}

const usersRole = {
  sysadmin: 'sysadmin',
  customer: 'customer',
};

const moduleName = {
  dashboard: 'dashboard',
  profile: 'profile',
  clusters: 'clusters',
};

const roleAction = {
  create: 'create',
  read: 'read',
  update: 'update',
};

const roleGroup = {
  C: [roleAction.create],
  R: [roleAction.read],
  U: [roleAction.update],
  CRU: [roleAction.create, roleAction.read, roleAction.update],
};

const publicRoleMatiks = [moduleName.profile];

const roleMatriks = {
  [usersRole.sysadmin]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.clusters]: roleGroup.CRU,
  },
  [usersRole.customer]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.clusters]: roleGroup.CRU,
  },
};

const componentConfig = {
  notification: {
    interval: 2500,
    position: NotificationPosition.topRight,
  },
  confirmation: {
    dismiss: {
      enabled: true,
      escapeKey: false,
      outsidePress: false,
      closeButton: false,
    } as ConfirmationDismissType,
    confirm: {
      title: 'Apakah kamu yakin?',
      message: 'Jika melanjutkan maka data yang sudah dimasukan akan hilang!',
    },
    alert: {
      title: 'Berhasil',
      message: 'Konten berhasil dibuat!',
    },
  },
  modal: {
    dismiss: {
      enabled: true,
      escapeKey: false,
      outsidePress: false,
      closeButton: false,
    } as ModalDismissType,
  },
};

export {
  storageKey,
  API_BASE_URL,
  roleMatriks,
  roleAction,
  moduleName,
  publicRoleMatiks,
  componentConfig,
  DateFormat,
};
