import { Fragment, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { validAuth } from '../utils/base';
import notification from 'src/stores/notification';

let hasNavigateBack = false;
const AuthProvider: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const hasValidAuth = validAuth();
    if (hasValidAuth.isValid && !hasNavigateBack) {
      hasNavigateBack = true;
      notification.error('Anda sudah login!');
      navigate('/dashboard/organizations');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default AuthProvider;
