import { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { storageKey } from '../constans';
import { useAuth } from '../stores/auth';
import { validAuth } from '../utils/base';
import { storage } from '../utils/storage';

import {
  DashboardContextProvider,
  DashboardLayout,
} from '../components/layouts';
import { LoadingScreen } from '../components/elements/loading-screen';

import notification from 'src/stores/notification';

let hasNavigateBack = false;
const DashboardProvider: React.FC = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hasValidAuth = validAuth();
    if (!hasValidAuth.isValid && !hasNavigateBack) {
      hasNavigateBack = true;
      notification.error(hasValidAuth.message);
      storage.removeItem(storageKey.authToken);
      navigate('/login', {
        state: {
          redirect: location.pathname,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, location.pathname]);
  return (
    <DashboardContextProvider>
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen reason='Get Dashboard Component' />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    </DashboardContextProvider>
  );
};

export default DashboardProvider;
