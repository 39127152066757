import React from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyImport } from '../utils/performance';
import { DashboardProvider } from '../providers/index';

const { OrganizationsRoutes } = lazyImport(
  () => import('../features/organization'),
  'OrganizationsRoutes'
);

export const protectedRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    element: <DashboardProvider />,
    children: [
      {
        path: 'organizations/*',
        element: <OrganizationsRoutes />,
      },
    ],
  },
];
