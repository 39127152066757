import clsx from 'clsx';
import React, { forwardRef } from 'react';

import { useFormControl } from '../index';

import { FormErrorProps } from './FormError.types';

export const FormError = forwardRef<HTMLDivElement, FormErrorProps>(
  (props, ref) => {
    const { className, id, ...rest } = props;
    const classes = clsx('form-error', className);
    const formControl = useFormControl({});

    return (
      <div
        ref={ref}
        className={classes}
        id={id || formControl.errorId}
        {...rest}
      />
    );
  }
);

FormError.displayName = 'FormError';
