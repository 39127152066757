import {
  FloatingFocusManager,
  FloatingPortal,
} from '@floating-ui/react-dom-interactions';
import React, { forwardRef, useMemo } from 'react';

import { mergeRefs } from '../../../utils/refs';

import { useDropdownContext } from './Dropdown';
import { DropdownMenuProps } from './Dropdown.types';

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  ({ children, className, ...rest }, ref) => {
    const { open, strategy, x, y, context, floating, getFloatingProps } =
      useDropdownContext();
    const mergedRef = useMemo(
      () => mergeRefs([ref, floating]),
      [floating, ref]
    );

    return (
      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context}>
            <div
              {...getFloatingProps({
                ...rest,
                ref: mergedRef,
                className: className,
                style: {
                  position: strategy,
                  top: y ?? '',
                  left: x ?? '',
                },
              })}
            >
              {children}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    );
  }
);

DropdownMenu.displayName = 'DropdownMenu';
