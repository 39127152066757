import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { localStorageUtils } from '../../../utils/localstorage';

import type { BaseLayoutProps } from '../../../types/components';

type DashboardContextType = {
  collapseSideMenu: boolean;
  handleCollapseSideMenu: (value: boolean) => void;
};

export const DashboardContext = React.createContext<DashboardContextType>({
  collapseSideMenu: true,
  handleCollapseSideMenu: () => {},
});

const DashboardContextProvider: React.FC<BaseLayoutProps> = ({ children }) => {
  const [collapseSideMenu, setCollapseSideMenu] = useState<boolean>(true);

  const { setLocalStorage, getLocalStorage } = localStorageUtils;

  useEffect(() => {
    const getCollapsed = getLocalStorage('collapsed-menu');
    if (getCollapsed !== null) setCollapseSideMenu(getCollapsed);
  }, [getLocalStorage]);

  const handleCollapseSideMenu = useCallback(
    (value: boolean) => {
      setCollapseSideMenu(value);
      setLocalStorage('collapsed-menu', value);
    },
    [setLocalStorage]
  );

  const contextValue = useMemo(
    () => ({
      collapseSideMenu,
      handleCollapseSideMenu,
    }),
    [collapseSideMenu, handleCollapseSideMenu]
  );

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
