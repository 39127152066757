import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { BaseCreateForm } from '../../../types/apis';

import axios from '../../../libs/axios';

import { LoginDTO, LoginFormDTO, LoginOptions } from '../types';

const login = async ({
  data,
  signal,
}: BaseCreateForm<LoginFormDTO>): Promise<AxiosResponse<LoginDTO>> => {
  return await axios.post('/auth/signin', data, {
    signal,
  });
};

const useLogin = (props?: LoginOptions) => {
  return useMutation({
    mutationFn: login,
    ...props?.config,
  });
};

export { login, useLogin };
