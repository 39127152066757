import clsx from 'clsx';
import React, { forwardRef } from 'react';

import ReactLoading from 'react-loading';

import useButtonClass from './Button.styles';
import { ButtonProps } from './Button.types';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      disabled,
      isLoading,
      loadingText,
      active,
      leftIcon,
      rightIcon,
      children,
      className,
      color,
      size,
      variant,
      isFluid,
      ...rest
    } = props;
    const isDisabled = disabled || isLoading;
    const classes = useButtonClass({ size, variant, isFluid, isDisabled });
    return (
      <button
        ref={ref}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        data-active={active ? 'true' : undefined}
        data-color={color || undefined}
        className={clsx(classes, className)}
        {...rest}
      >
        <>
          {leftIcon && !isLoading ? leftIcon : null}
          {isLoading && (
            <ReactLoading
              type='spin'
              className={clsx(
                loadingText ? 'relative' : 'absolute',
                loadingText ? 'mr-2' : 'mr-0'
              )}
              height={20}
              width={20}
            />
          )}
          {isLoading
            ? loadingText || <span className='opacity-0'>{children}</span>
            : children}
          {rightIcon && !isLoading ? rightIcon : null}
        </>
      </button>
    );
  }
);

Button.displayName = 'Button';
