import { nanoid } from 'nanoid';

import { componentConfig } from '../constans';

import { createState } from '../libs/state';

import {
  NotificationData,
  NotificationFunc,
  NotificationProps,
  NotificationState,
  NotificationType,
} from '../components/notifications';

const { setState, useState } = createState({
  notification: {
    data: [],
    position: 'topRight',
  } as NotificationState,
});

export const useNotification = () => useState('notification');

export const pushNotification = (props: NotificationProps) => {
  const newNotification: NotificationData = {
    ...props,
    id: nanoid(),
    lifetime: props?.lifetime || componentConfig.notification.interval,
  };
  setState('notification', (prev) => ({
    ...prev,
    data: [...prev.data, newNotification],
  }));
};

export const removeNotification = (id: string) => {
  setState('notification', (prev) => ({
    ...prev,
    data: prev.data.filter((e) => e.id !== id),
  }));
};

const notification: NotificationFunc = {
  error: (message: string, lifetime?: number) => {
    pushNotification({ message, lifetime, type: NotificationType.error });
  },
  warning: (message: string, lifetime?: number) => {
    pushNotification({ message, lifetime, type: NotificationType.warning });
  },
  success: (message: string, lifetime?: number) => {
    pushNotification({ message, lifetime, type: NotificationType.success });
  },
  info: (message: string, lifetime?: number) => {
    pushNotification({ message, lifetime, type: NotificationType.info });
  },
};

export default notification;
